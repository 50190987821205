// TODO: unused, remove?
// sort string with numbers
// eslint-disable-next-line no-redeclare,no-unused-vars
function naturalCompare(a, b, key) {
  var ax = [],
    bx = [];

  if (key) {
    a = a[key];
    b = b[key];
  }

  if (a)
    a.replace(/(\d+)|(\D+)/g, function (_, $1, $2) {
      ax.push([$1 || Infinity, $2 || '']);
    });
  if (b)
    b.replace(/(\d+)|(\D+)/g, function (_, $1, $2) {
      bx.push([$1 || Infinity, $2 || '']);
    });

  while (ax.length && bx.length) {
    var an = ax.shift();
    var bn = bx.shift();
    var nn = an[0] - bn[0] || an[1].localeCompare(bn[1]);
    if (nn) return nn;
  }

  return ax.length - bx.length;
}
